<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6 justify-content-center">
        <b-card-title
          v-b-tooltip.hover
          class="text-center mb-1"
          title="Default variant"
          variant="gradient-primary"
        >
          Current limit
          <feather-icon
            v-b-tooltip.hover.top="'During testing we are limiting the Amount of Viewers during high-demand (Once you started your viewers won\'t decrease)'"
            class="text-center mb-1"
            title="Default variant"
            variant="gradient-primary"
            icon="InfoIcon"
          />

        </b-card-title>
        <b-card-text class="text-muted font-small-2">
          <vue-apex-charts
            height="120"
            :options="earningsChart.chartOptions"
            :series="data.series"
          />
          <!--          <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>-->
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BCard, BRow, BCol, BCardTitle, BCardText, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

// const $earningsStrokeColor2 = '#28c76f33'
// const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    VBTooltip,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['Available', 'In-Use', 'Product'],
          stroke: { width: 0 },
          colors: [$themeColors.success, $themeColors.danger],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Limit',
                    test: this.data.series[1].toString(),
                    formatter() {
                      return `${this.test}%`
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  watch: {
    data() {
      console.log('TEST')
      console.log(this.data)
      this.earningsChart.chartOptions = {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        labels: ['Available', 'In-Use', 'Product'],
        stroke: { width: 0 },
        colors: [$themeColors.success, $themeColors.danger],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: 'Limit',
                  test: this.data.series[1].toString(),
                  formatter() {
                    return `${this.test}%`
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
      }
      // this.earningsChart.chartOptions.plotOptions.pie.donut.labels.total = {
      //   show: true,
      //   offsetY: 15,
      //   label: 'Limit',
      //   formatter() {
      //     // eslint-disable-next-line quotes
      //     return `50%`
      //   },
      // }
    },
  },
}
</script>
