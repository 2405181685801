<template>
  <b-card
    title="Enter your Twitchname to Start"
  >
    <b-row>
      <b-col md="6">
        <b-form-group
          novalidate
          class="needs-validation"
          label="Twitch username"
          label-for="twitchAccount"
          @submit.prevent
        >
          <b-form-input
            id="twitchAccount"
            v-model="twitch"
            placeholder="Streamerzone"
            :state="twitch.length > 0"
          />
          <!--          <b-form-valid-feedback-->
          <!--            tooltip-->
          <!--          >-->
          <!--            Looks good!-->
          <!--          </b-form-valid-feedback>-->
          <b-form-invalid-feedback
            tooltip
          >
            Please provide a valid Twitchname
          </b-form-invalid-feedback>
        </b-form-group>

      </b-col>
    </b-row>
    <!-- toggle button -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      :disabled="twitch.length === 0 || twitch === 'null' || twitch === null || activateEnabled === false"
      @click="checklicense()"
    >
      Activate
    </b-button>
  </b-card>
</template>
<script>
/* eslint-disable no-unused-vars,vue/no-unused-components,no-unreachable */
import { getUserData } from '@/auth/utils'
import firebase from 'firebase'
import {
  BCard,
  BCardText,
  BForm,
  BRow,
  BButton,
  BFormInput,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  name: 'ActivateLicense',
  components: {
    BCard,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: {},
      activateEnabled: false,
      twitch: '',
      code: 'xxxx-xxxx-xxxx',
    }
  },
  mounted() {
    const userData = getUserData()
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        this.twitch = snap.data().twitchname
        this.twitch ??= 'YourTwitchname'
        if (this.twitch === 'null') this.twitch = 'YourTwitchname'
        this.username = snap.data().username
        if (snap.data().preSavedSerial !== undefined) this.activateEnabled = true
      })
  },
  methods: {
    overdue(activationDate, totalDays) {
      const date1 = activationDate.toDate()
      const date2 = new Date()
      const differenceInTime = date2.getTime() - date1.getTime()

      // To calculate the no. of days between two dates
      const days = differenceInTime / (1000 * 3600 * 24)
      const remainingduration = totalDays - days.toFixed(0)
      if (remainingduration < 0) return true
      return false
    },
    async checklicense() {
      this.twitch = this.twitch.trim()
      if (this.twitch.includes('/')) {
        const test = this.twitch.split('/')
        this.twitch = test[test.length - 1]
      }
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      const userDoc = await sx.collection('User').doc(userData.id).get()
      this.code = userDoc.data().preSavedSerial
      console.log(this.code)

      if (this.twitch.length > 0) {
        let cancel = false
        const licenseDoc = await sx.collection('License').doc(this.code).get().catch(() => {
          cancel = true
        })
        if (!licenseDoc.exists) {
          this.$swal({
            // icon: 'success',
            title: 'Warning!',
            icon: 'warning',
            text: 'This License does not exist, if you believe that this is not intended, please contact our Support via Discord or Mail!',
            showConfirmButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          return
        }
        await sx.collection('License').doc(this.code).update({
          Used: true,
          user: userData.id,
          twitchname: this.twitch.toLowerCase(),
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        if (licenseDoc.data().Used === true) {
          this.$swal({
            title: 'Error!',
            text: ' This License was already redeemed, if you believe that this is an error, please contact our Support via Discord or Mail!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          return
        }
        if (userDoc.data().Package !== 'None') {
          // save old License
          await sx.collection('User').doc(userData.id).collection('oldLicenses').add({
            timestamp: new Date(),
            totalDays: userDoc.data().License.TotalDays,
            licensekey: userDoc.data().License.licensekey,
            licensebeginning: userDoc.data().License.licensebeginning,
            lastStart: userDoc.data().License.LastStart,
            package: userDoc.data().Package,
            streamerzone: true,
            viewer: userDoc.data().viewer,
            twitchname: userDoc.data().twitchname,
            follower: {
              remaining: userDoc.data().follower.remaining, sent: userDoc.data().follower.sent, realRemaining: userDoc.data().follower.realRemaining, realSent: userDoc.data().follower.realSent,
            },
          })
        }
        // set new License
        await sx.collection('User').doc(userData.id).set({
          License: {
            DaysLeft: licenseDoc.data().Duration,
            TotalDays: licenseDoc.data().Duration,
            licensekey: this.code,
            streamerzone: true,
            licensebeginning: firebase.firestore.FieldValue.serverTimestamp(),
            LastStart: new Date(),
          },
          resell: true,
          streamerzone: true,
          Package: licenseDoc.data().Package,
          viewer: licenseDoc.data().Viewer,
          twitchname: this.twitch.toLowerCase(),
          follower: {
            remaining: licenseDoc.data().Follower, sent: 0, realRemaining: licenseDoc.data().Follower, realSent: 0, sendMessage: true, dripFeedAmount: 10, dripFeedTime: 25, currentMessage: '',
          },
          Botpanel: {
            Views: 5,
            Active: false,
            Viewlist: true,
            CategoryChatbot: false,
            Chatbot: false,
            Viewbot: true,
            dripFeedTime: 900,
            chatinterval: [30, 300],
            Loop: true,
          },
        }, { merge: true })
          .then(() => {
            this.show = false
            this.showstart = true
            this.$swal({
              title: 'Succeed!',
              text: ` You just redeemed your ${licenseDoc.data().Package}Package!`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            if (this.$router.currentRoute.path === '/pages/Panel') this.$router.go()
            this.$router.push('/pages/Panel')
          })
        // .then(snap => {
        //   if (snap.data().Used === false) {
        //     sx.collection('License')
        //         .doc(this.code)
        //         .update({
        //           Used: true,
        //           user: userData.id,
        //           twitchname: this.twitch.toLowerCase(),
        //           timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        //         })
        //         .then(() => {
        //           sx.collection('User')
        //               .doc(userData.id)
        //               .set({
        //                 License: {
        //                   DaysLeft: snap.data().Duration,
        //                   TotalDays: snap.data().Duration,
        //                   licensekey: this.code,
        //                   licensebeginning: firebase.firestore.FieldValue.serverTimestamp(),
        //                   LastStart: new Date(),
        //                 },
        //                 Package: snap.data().Package,
        //                 viewer: snap.data().Viewer,
        //                 twitchname: this.twitch.toLowerCase(),
        //                 follower: {
        //                   remaining: snap.data().Follower, sent: 0, realRemaining: snap.data().Follower, realSent: 0, sendMessage: true, dripFeedAmount: 10, dripFeedTime: 25, currentMessage: '',
        //                 },
        //                 Botpanel: {
        //                   Views: 5,
        //                   Active: false,
        //                   Viewlist: true,
        //                   CategoryChatbot: true,
        //                   Chatbot: true,
        //                   Viewbot: true,
        //                   chatinterval: [30, 300],
        //                   Loop: true,
        //                 },
        //               }, { merge: true })
        //               .then(() => {
        //                 sx.collection('User')
        //                     .doc(userData.id)
        //                     .get()
        //                     .then(snap2 => {
        //                       // console.log(snap.data().Package)
        //                       // const expiresIn = 7200
        //                       // const createdAt = admin.firestore.Timestamp.now().toDate()
        //                       // createdAt.setSeconds(createdAt.getSeconds() + expiresIn);
        //                       // admin.firestore.Timestamp.fromDate(createdAt)
        //                       if (snap2.data().Package === 'None') {
        //                         this.show = true
        //                       } else {
        //                         this.valueslider = snap2.data().Botpanel.Views
        //                         this.max = snap2.data().viewer
        //                         this.active = snap2.data().Botpanel.Active
        //                         this.showstart = !snap2.data().Botpanel.Active
        //                         this.showstop = snap2.data().Botpanel.Active
        //                         this.chatbot = snap2.data().Botpanel.Chatbot
        //                         this.viewbot = snap2.data().Botpanel.Viewbot
        //                         this.timeslider = snap2.data().Botpanel.chatinterval
        //                         if (snap2.data().Botpanel.chatinterval === snap2.data().Botpanel.chatintervalxx) {
        //                           this.timeslider = [30, 180]
        //                         }
        //                         // console.log(snap.data().Botpanel.chatinterval)
        //                       }
        //                     })
        //                     .then(() => {
        //                       this.show = false
        //                       this.showstart = true
        //                       this.$swal({
        //                         title: 'Succeed!',
        //                         text: ` You just redeemed your ${snap.data().Package}Package!`,
        //                         icon: 'success',
        //                         customClass: {
        //                           confirmButton: 'btn btn-primary',
        //                         },
        //                         buttonsStyling: false,
        //                       })
        //                       this.$router.go()
        //                     })
        //               })
        //               .catch(error => {
        //                 console.log(error)
        //                 this.$swal({
        //                   title: 'Errors!',
        //                   text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
        //                   icon: 'error',
        //                   customClass: {
        //                     confirmButton: 'btn btn-primary',
        //                   },
        //                   buttonsStyling: false,
        //                 })
        //               })
        //         })
        //   } else {
        //     this.$swal({
        //       title: 'Error!',
        //       text: ' This License was already redeemed, if you believe that this is an error, please contact our Support via Discord or Mail!',
        //       icon: 'error',
        //       customClass: {
        //         confirmButton: 'btn btn-primary',
        //       },
        //       buttonsStyling: false,
        //     })
        //   }
        // })
      } else {
        this.$swal({
          title: 'Error!',
          text: ' Please enter a twitchname!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },

  },
}
</script>

<style scoped>

</style>
