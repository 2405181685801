<template>
  <div>
    <ActivateLicense v-if="show" />
    <b-overlay
      :show="show"
      rounded="sm"
      @shown="onShown"
      @hidden="onHidden"
    >
      <b-alert
        v-if="raidActive"
        variant="info"
        show
      >
        <div class="alert-body">
          <span><strong>Raid Active!</strong>  Raided Channel: {{ raidedChannel }}</span>
        </div>
      </b-alert>
      <b-overlay
        :show="active || raidActive"
        rounded="sm"
        @shown="onShown"
        @hidden="onHidden"
      >
        <b-card
          title="Start/Stop"
        >
          <b-card-text class="mb-0">
            <!--        <span>Start/stop </span>-->
            <!--        <code>switch</code>-->
            <!--        <span> to </span>-->
            <!--        <code>true</code>-->
          </b-card-text>

          <!-- switch -->
          <b-row>
            <b-col class="col-5">
              <b-button
                v-if="showstart && showAutoStart"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="disableStart"
                variant="success"
                class="mb-1 mt-1"
                @click="startbot"
              >
                <feather-icon
                  icon="PlayIcon"
                  class="mr-25"
                />
                <span>Start Adrolls</span>
              </b-button>
            </b-col>

            <b-col>
              <b-button
                v-if="showstart && showAutoStart"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="disableStart"
                variant="success"
                class="mb-1 mt-1"
                @click="enableAutoStart"
              >
                <feather-icon
                  icon="PlayIcon"
                  class="mr-25"
                />
                <span>Enable Autostart</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <template #overlay>
          <div class="text-center">
            <b-icon
              icon="CaretRightFill"
              font-scale="3"
              animation="pulse"
            />
            <p id="startstop">
              Adrolls running
            </p>
            <b-alert
              v-if="raidActive"
              variant="info"
              show
            >
              <div class="alert-body">
                <span><strong>Raid Active!</strong> Raided Channel: {{ raidedChannel }}</span>
              </div>
            </b-alert>
            <b-button
              v-if="!showAutoStart && AutoStartEnabled"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="disableStart"
              variant="danger"
              class="mb-1 mt-1"
              @click="disableAutoStart"
            >
              <feather-icon
                icon="PlayIcon"
                class="mr-25"
              />
              <span>Disable Autostart</span>
            </b-button>
            <b-button
              v-if="showstop && !AutoStartEnabled"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mb-1 mt-1"
              @click="stopbot"
            >
              <feather-icon
                icon="AlertOctagonIcon"
                class="mr-25"
              />
              <span>Stop Viewers</span>
            </b-button>
          </div>
        </template>
      </b-overlay>
      <!--      <CreditHistory />-->
      <div>
        <b-overlay
          :show="active"
          rounded="sm"
          @shown="onShown"
          @hidden="onHidden"
        >
          <b-card
            title="Embedding Settings"
          >
            <b-row>
              <b-col
                v-if="!fluctuatingViewersActivated"
                md="6"
              >
                <b-form-group
                  label="Viewercount"
                >
                  <div class="mt-3">
                    <vue-slider
                      v-if="!fluctuatingViewersActivated"
                      v-model="valueslider"
                      :min="1"
                      :max="max"
                      :direction="'ltr'"
                      :drag-on-click="true"
                      :tooltip="'always'"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                v-if="fluctuatingViewersActivated"
                md="6"
              >
                <label for="fluctuatingViewerSlider">
                  <div class="font-small-4">Viewercount                 <feather-icon
                    v-b-tooltip.hover.top="'Viewers will fluctuate in between the range'"
                    icon="AlertCircleIcon"
                    class="mr-25"
                  /></div>

                </label>
                <b-form-group
                  id="fluctuatingViewerSlider"
                >
                  <div class="mt-3">
                    <vue-slider
                      v-if="fluctuatingViewersActivated"
                      v-model="fluctuatingViewSlider"
                      :min="1"
                      :max="max"
                      :direction="'ltr'"
                      :drag-on-click="true"
                      :tooltip="'always'"
                    />
                  </div>
                </b-form-group>

              </b-col>
              <b-col
                v-if="fluctuatingViewersActivated"
                md="3"
              >
                <label for="fluctuatingViewerTime">
                  <div class="font-small-4">Time range for fluctuation in Minutes <feather-icon
                    v-b-tooltip.hover.top="'Amount of Viewers will change in a random Timeframe in between your selected Time'"
                    icon="AlertCircleIcon"
                    class="mr-25"
                  /></div>

                </label>
                <b-form-group
                  id="fluctuatingViewerTime"
                >
                  <div class="mt-3">
                    <vue-slider
                      v-model="fluctuatingViewerTime"
                      :min="5"
                      :max="60"
                      :direction="'ltr'"
                      :drag-on-click="true"
                      :tooltip="'always'"
                    />
                  </div>
                </b-form-group>

              </b-col>
            </b-row>
            <!--            This is the Testing Chart-->
            <!--            <b-row>-->
            <!--              <b-col>-->
            <!--                <ecommerce-earnings-chart :data="usage" />-->
            <!--              </b-col>-->
            <!--            </b-row>-->

          </b-card>
          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="CaretRightFill"
                font-scale="3"
                animation="pulse"
              />

              <p
                v-if="!dripfeedActive && !viewerChangeActive"
                id="startstop"
              >
                Adrolls running
              </p>
              <p v-if="dripfeedActive">
                Dripfeed is Active
              </p>
              <p v-if="viewerChangeActive">
                Viewer-Change in progress
              </p>
            </div>
            <b-row>
              <!--              TODO Add new State if Dripfeed is active-->
              <b-col
                v-if="(dripfeedActive || viewerChangeActive) && !fluctuatingViewersActivated"
                style="min-width: 45vw"
              >
                <b-progress
                  :max="100"
                  variant="warning"
                >
                  <b-progress-bar
                    v-if="viewerChangeActive || dripfeedActive "
                    :value="barProgress"
                  >
                    <strong>{{ barProgress }}% </strong>
                  </b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
            <b-row
              v-if="!dripfeedActive && !viewerChangeActive && !fluctuatingViewersActivated"
              style="width: 50vw"
            >
              <b-col>
                <vue-slider
                  v-model="updatedViewSlider"
                  :min="1"
                  :max="max"
                  :direction="'ltr'"
                  :drag-on-click="true"
                  :tooltip="'always'"
                />
              </b-col>
            </b-row>
            <b-row v-if="fluctuatingViewersActivated">
              <b-col class="justify-content-center">
                Current Views: {{ valueslider }}
              </b-col>
            </b-row>
            <b-row v-if="!dripfeedActive && !viewerChangeActive && !fluctuatingViewersActivated">
              <b-col class="justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  variant="warning"
                  class="mb-1 mt-1"
                  @click="updateViewers"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-25"
                  />
                  <span>Update Viewers</span>
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-overlay>
      </div>

      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="cash"
            font-scale="3"
            animation="pulse"
          />
          <p id="cancel-label">
            {{ overlayMessage }}
          </p>
        </div></template>
    </b-overlay>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BFormCheckbox,
  BIconCaretRightFill,
  BImg,
  BOverlay,
  BAlert,
  BNavItemDropdown,
  BCardText,
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BCol,
  BRow,
  BIcon,
  BIconCash,
  BFormValidFeedback,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormInvalidFeedback,
  VBTooltip,
  BProgress, BProgressBar,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import VueSlider from 'vue-slider-component'
import firebase from 'firebase'
import ActivateLicense from '../../components/license/activateLicense.vue'

import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
// import CreditHistory from '@/views/pages/Panel/CreditHistory.vue'

export default {
  components: {
    // CreditHistory,
    BCol,
    BOverlay,
    BRow,
    BAlert,
    BProgress,
    BProgressBar,
    ActivateLicense,
    BImg,
    BNavItemDropdown,
    EcommerceEarningsChart,
    BFormCheckbox,
    BCard,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    VueSlider,
    BFormInput,
    BButton,
    VBTooltip,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BIconCaretRightFill,
    BIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      overlayMessage: 'Please buy a license',
      fluctuatingViewerMaxCountChange: [2, 3],
      fluctuatingViewerTime: [2, 6],
      fluctuatingViewSlider: [5, 10],
      fluctuatingViewersActivated: false,
      timerFunctionActive: false,
      viewerChangeActive: false,
      dripfeedActive: false,
      updatedViewSlider: 1,
      barProgress: 0,
      AutoStartEnabled: false,
      showAutoStart: true,
      disableStart: false,
      viewCapacity: { max: 10, current: 0 },
      usage: { series: [100, 0] },
      timeslider: [30, 180],
      selectedCountry: 'Germany',
      loop: true,
      minutes: 0,
      seconds: 15,
      max: 15,
      valueslider: 1,
      viewlistslider: 5,
      code: null,
      show: false,
      twitch: '',
      active: false,
      viewlist: false,
      chatbot: true,
      viewbot: true,
      showstart: false,
      showstop: false,
      raidBotActivated: true,
      raidActive: false,
      raidedChannel: 'None',
      dripFeedTime: 60,
      customMaxViewList: 50,
      customViewListActivated: false,
    }
  },
  computed: {
    maxViewList() {
      if (this.customViewListActivated) return this.customMaxViewList
      if (this.fluctuatingViewersActivated) return this.fluctuatingViewSlider[1]
      return this.valueslider
    },
    maxFluctuation() {
      const returnValue = this.fluctuatingViewSlider[1] - this.fluctuatingViewSlider[0]
      return (returnValue > 0) ? returnValue : 1
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    fluctuatingViewersActivated() {
      if (!this.fluctuatingViewersActivated) this.valueslider -= 1
    },
    timeslider() {
      const x = this.timeslider / 60
      const comma = (x * 10) % 10
      this.minutes = x - comma
      this.seconds = this.timeslider % 60
    },
    valueslider() {
      // CreditHistory.methods.updateViewerCalc()
      if (this.valueslider > this.max) {
        this.valueslider = this.max
      }
      if (this.valueslider < this.viewlistslider) {
        this.viewlistslider = this.valueslider
      }
      if (this.valueslider < 1) {
        this.valueslider = 1
      }
      this.updatedViewSlider = this.valueslider
    },
    viewlistslider() {
      if (this.viewlistslider > this.valueslider) {
        this.viewlistslider = this.valueslider
      }
      if (this.viewlistslider < 0) {
        this.viewlistslider = 0
        this.disableStart = true
      }
    },
  },
  created() {
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
  mounted() {
    this.startListener()
  },

  methods: {
    coinflip() {
      return (Math.round(Math.random()) + 1)
    },
    async updateTimes(endTime) {
      while (this.viewerChangeActive || this.dripfeedActive || this.timerFunctionActive) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise(r => setTimeout(r, 1000))
        if (this.dripfeedActive) {
          this.barProgress = Math.round(100 - ((((endTime - new Date()) / 1000) / this.dripFeedTime) * 100))
        } else if (this.viewerChangeActive) {
          this.barProgress = Math.round(100 - ((((endTime - new Date()) / 1000) / 180) * 100))
        } else if (this.barProgress > 100) {
          this.timerFunctionActive = false
          this.viewerChangeActive = false
          this.dripfeedActive = false
        }
        if (this.barProgress > 100) this.barProgress = 100
      }
    },
    updateViewers() {
      if (this.updatedViewSlider > this.max) {
        this.updatedViewSlider = this.max
      }

      // eslint-disable-next-line no-empty
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .get()
        .then(snap => {
          const oldLicense = snap.data().License
          oldLicense.LastStart = new Date()
          this.showAutoStart = false
          sx.collection('User')
            .doc(userData.id)
            .set({
              Botpanel: {
                Views: this.updatedViewSlider,
                updatedViewsDate: new Date(),
              },
              raid: {
                active: false,
              },
            }, { merge: true })
            .then(() => {
              this.$swal({
                title: 'Updated Viewers!',
                text: 'Viewers will change in the next 3 Minutes.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              console.log(error)
              this.showstop = false
              this.showstart = true
              this.$swal({
                title: 'Errors!',
                text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        })
        .catch(error => {
          console.log(error)
          this.showstop = false
          this.showstart = true
          this.$swal({
            title: 'Errors!',
            text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    Tformatter(val) {
      const hour = Math.floor((val / 60) / 60)
      const zwVal = Math.floor(val / 60)
      const min = zwVal % 60 > 9 ? zwVal % 60 : `0${zwVal % 60}`
      const sec = val % 60 > 9 ? val % 60 : `0${val % 60}`
      return `${hour}:${min}:${sec}`
    },
    startListener() {
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .onSnapshot(snap => {
          if (snap.data().Package === 'None') {
            this.show = true
            if (snap.data().preSavedSerial !== undefined) this.overlayMessage = 'Please enter your Twitchname to start your License!'
          } else {
            this.raidedChannel = snap.data().raid?.twitchname
            this.raidActive = snap.data().raid?.active
            this.valueslider = snap.data().Botpanel.Views
            this.max = snap.data().viewer
            this.active = snap.data().Botpanel.Active
            this.showstart = !snap.data().Botpanel.Active
            this.showstop = snap.data().Botpanel.Active
            this.viewlist = snap.data().Botpanel.Viewlist
            this.chatbot = snap.data().Botpanel.Chatbot
            this.viewbot = snap.data().Botpanel.Viewbot
            this.timeslider = snap.data().Botpanel.chatinterval
            this.dripFeedTime = snap.data().Botpanel.DripFeedTime
            if (this.raidActive) this.showstop = true
            try {
              this.raidBotActivated = snap.data().Botpanel.RaidActivated
            } catch (e) {
              this.raidBotActivated = false
            }
            try {
              this.showAutoStart = !snap.data().Botpanel.AutoStart
              this.AutoStartEnabled = snap.data().Botpanel.AutoStart
              if (this.AutoStartEnabled) {
                this.active = true
              }
            } catch (e) {
              this.showAutoStart = true
              this.AutoStartEnabled = false
            }
            if (this.dripFeedTime === undefined) {
              this.dripFeedTime = 1
            }
            if (snap.data().Botpanel.chatinterval === snap.data().Botpanel.chatintervalxx) {
              this.timeslider = [30, 180]
            }
            if (snap.data().Botpanel.ViewlistCount === snap.data().Botpanel.xxx) {
              this.viewlistslider = 1
            } else {
              this.viewlistslider = snap.data().Botpanel.ViewlistCount
            }
            if (snap.data().Botpanel.Loop === snap.data().Botpanel.xxx) {
              this.loop = true
            } else {
              this.loop = snap.data().Botpanel.Loop
            }
            this.raidBotActivated ??= true
            if (snap.data().Botpanel.Country === snap.data().Botpanel.xxx) {
              this.selectedCountry = 'Germany'
            } else {
              this.selectedCountry = snap.data().Botpanel.Country
            }
            // if Time + 180*1000 > new Date -> updateViewersActive
            try {
              const endOfUpdatedViews = new Date(snap.data().Botpanel?.updatedViewsDate.seconds * 1000 + 180000)
              if (endOfUpdatedViews > new Date()) {
                this.viewerChangeActive = true
                this.updateTimes(endOfUpdatedViews)
              }
            } catch (e) {
              this.viewerChangeActive = false
            }
            try {
              const endOfDripFeed = new Date((snap.data().License?.LastStart.seconds * 1000) + this.dripFeedTime * 1000)
              if (endOfDripFeed > new Date()) {
                this.dripfeedActive = true
                this.updateTimes(endOfDripFeed)
              }
            } catch (e) {
              this.dripfeedActive = false
            }
            try {
              this.fluctuatingViewersActivated = snap.data().Botpanel.fluctuatingViewersActivated
              this.fluctuatingViewSlider = snap.data().fluctuatingViewers.fluctuatingViewerCount
              this.fluctuatingViewerTime = snap.data().fluctuatingViewers.fluctuatingViewerTime
              this.fluctuatingViewerMaxCountChange = snap.data().fluctuatingViewers.fluctuatingViewerMaxCount
            } catch (e) {
              this.fluctuatingViewersActivated = false
            }
            try {
              this.customMaxViewList = snap.data().Botpanel.customMaxViewList
              this.customViewListActivated = snap.data().Botpanel.customViewListActivated
            } catch (e) {
              this.customMaxViewList = 50
              this.customViewListActivated = false
            }
            this.customMaxViewList ??= 50
            this.customViewListActivated ??= false

            this.fluctuatingViewersActivated ??= false
            this.fluctuatingViewSlider ??= [5, 10]
            this.fluctuatingViewerTime ??= [2, 6]
            this.fluctuatingViewerMaxCountChange ??= [2, 3]
          }
        })
    },
    changepattern(country) {
      this.selectedCountry = country
    },
    switchlooptrue() {
      this.loop = true
    },
    switchloopfalse() {
      this.loop = false
    },
    // fetch(`https://${snap2.data().Link}.ngrok.io/start/${userData.id}/${this.chatbot}/${snap.data().twitchname}/${this.valueslider}/${this.timeslider[0]}/${this.timeslider[1]}`)
    startbot() {
      if (this.valueslider > this.max) {
        this.valueslider = this.max
      }
      // eslint-disable-next-line no-empty
      if (this.ViewlistCount > 0) {
      } else {
        this.ViewlistCount = 0
      }
      this.fluctuatingViewersActivated ??= false
      if (this.fluctuatingViewersActivated) {
        // eslint-disable-next-line prefer-destructuring
        this.valueslider = this.fluctuatingViewSlider[0]
      }
      this.showstop = true
      this.showstart = false
      this.AutoStartEnabled = false
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .get()
        .then(snap => {
          sx.collection('System')
            .doc('NGROK')
            .get()
            .then(snap2 => {
              // fetch(`http://localhost:5000/start/${userData.id}/${this.chatbot}/${snap.data().twitchname}/${this.valueslider}/${this.timeslider[0]}/${this.timeslider[1]}`)
              fetch(`https://${snap2.data().Link}/start/${userData.id}`)
                .then(response => response.json())
              // eslint-disable-next-line no-unused-vars
                .then(data => {
                  const oldLicense = snap.data().License
                  oldLicense.LastStart = new Date()
                  this.showAutoStart = false
                  sx.collection('User')
                    .doc(userData.id)
                    .set({
                      Botpanel: {
                        fluctuatingViewersActivated: this.fluctuatingViewersActivated,
                        Views: this.valueslider,
                        Active: true,
                        Viewlist: this.viewlist,
                        CategoryChatbot: true,
                        Chatbot: this.chatbot,
                        Viewbot: this.viewbot,
                        chatinterval: this.timeslider,
                        Loop: this.loop,
                        Country: this.selectedCountry,
                        ViewlistCount: this.viewlistslider,
                        DripFeedTime: this.dripFeedTime,
                        RaidActivated: this.raidBotActivated,
                      },
                      fluctuatingViewers: {
                        fluctuatingViewerMaxCount: this.fluctuatingViewerMaxCountChange,
                        fluctuatingViewerCount: this.fluctuatingViewSlider,
                        fluctuatingViewerTime: this.fluctuatingViewerTime,
                      },
                      raid: {
                        active: false,
                      },
                      License: oldLicense,
                    }, { merge: true })
                    .then(() => {
                      this.active = true
                      this.showstop = true
                      this.showstart = false
                      this.$swal({
                        title: 'Started Bot!',
                        text: 'Selected options will start in the next 60 seconds!',
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      this.showstop = false
                      this.showstart = true
                      this.$swal({
                        title: 'Errors!',
                        text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                        icon: 'error',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.showstop = false
                  this.showstart = true
                  this.$swal({
                    title: 'Errors!',
                    text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            })
        })
    },
    enableAutoStart() {
      if (this.valueslider > this.max) {
        this.valueslider = this.max
      }
      // eslint-disable-next-line no-empty
      if (this.ViewlistCount > 0) {
      } else {
        this.ViewlistCount = 0
      }
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      this.showAutoStart = false
      this.active = true
      this.AutoStartEnabled = true
      sx.collection('User')
        .doc(userData.id)
        .set({
          Botpanel: {
            fluctuatingViewersActivated: this.fluctuatingViewersActivated,
            Views: this.valueslider,
            Active: false,
            Viewlist: this.viewlist,
            CategoryChatbot: true,
            Chatbot: this.chatbot,
            Viewbot: this.viewbot,
            chatinterval: this.timeslider,
            Loop: this.loop,
            Country: this.selectedCountry,
            ViewlistCount: this.viewlistslider,
            DripFeedTime: this.dripFeedTime,
            RaidActivated: this.raidBotActivated,
            AutoStart: true,
            AutoStartDeactivated: false,
          },
          fluctuatingViewers: {
            fluctuatingViewerMaxCount: this.fluctuatingViewerMaxCountChange,
            fluctuatingViewerCount: this.fluctuatingViewSlider,
            fluctuatingViewerTime: this.fluctuatingViewerTime,
          },
          raid: {
            active: false,
          },
        }, { merge: true })
        .then(() => {
          this.$swal({
            title: 'Enabled Autostart!',
            text: 'Now your Adrolls will automatically start and stop when you start your Stream!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    disableAutoStart() {
      if (this.valueslider > this.max) {
        this.valueslider = this.max
      }
      // eslint-disable-next-line no-empty
      if (this.ViewlistCount > 0) {
      } else {
        this.ViewlistCount = 0
      }
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      this.showAutoStart = true
      this.active = false
      this.AutoStartEnabled = false
      sx.collection('User')
        .doc(userData.id)
        .set({
          Botpanel: {
            Views: this.valueslider,
            Active: false,
            Viewlist: this.viewlist,
            CategoryChatbot: true,
            Chatbot: this.chatbot,
            Viewbot: this.viewbot,
            chatinterval: this.timeslider,
            Loop: this.loop,
            Country: this.selectedCountry,
            ViewlistCount: this.viewlistslider,
            DripFeedTime: this.dripFeedTime,
            RaidActivated: this.raidBotActivated,
            AutoStart: false,
            AutoStartDeactivated: true,
          },
          raid: {
            active: false,
          },
        }, { merge: true })
        .then(() => {
          this.$swal({
            title: 'Disabled Autostart!',
            text: 'Your Adrolls will no longer automatically start or stop when you start or stop your stream!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    stopbot() {
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      if (this.viewlistslider === this.nonexistent) {
        this.viewlistslider = 5
      }
      sx.collection('System')
        .doc('NGROK')
        .get()
        .then(snap => {
          // fetch(`http://localhost:5000/stop/${userData.id}`)
          fetch(`https://${snap.data().Link}/stop/${userData.id}`)
            .then(response => response.json())
          // eslint-disable-next-line no-unused-vars
            .then(data => {
              this.showAutoStart = true
              sx.collection('User')
                .doc(userData.id)
                .get()
              // eslint-disable-next-line no-unused-vars
                .then(snap2 => {
                  sx.collection('User')
                    .doc(userData.id)
                    .set({
                      Botpanel: {
                        Views: this.valueslider,
                        Active: false,
                        Viewlist: this.viewlist,
                        CategoryChatbot: true,
                        Chatbot: this.chatbot,
                        Viewbot: this.viewbot,
                        chatinterval: this.timeslider,
                        Loop: this.loop,
                        ViewlistCount: this.viewlistslider,
                        DripFeedTime: this.dripFeedTime,
                      },
                      raid: {
                        active: false,
                      },
                    }, { merge: true })
                    .then(() => {
                      this.showstop = false
                      this.showstart = true
                      this.active = false
                      this.$swal({
                        title: 'Stopped Bot!',
                        text: 'Bot will stop in the next 2 Minutes!',
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      this.$swal({
                        title: 'Errors!',
                        text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                        icon: 'error',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                })
            })
          // .then(response => {
          //   console.log(response.json())
          //   console.log('SUCCESSS')
          // })
            .catch(error => {
              console.log(error)
              this.$swal({
                title: 'Errors!',
                text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        })
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
    },
    onHidden() {
      // Focus the show button when the overlay is removed
    },
    // top start
    checklicense() {
      if (this.twitch.length > 0) {
      // console.log(`user id: ${firebase.auth().currentUser.uid}`)
        const userData = getUserData()
        // console.log(this.twitch)
        const sx = this.$firebase.firestore()
        sx.collection('License')
          .doc(this.code)
          .get()
          .then(snap => {
            if (snap.data().Used === false) {
              sx.collection('License')
                .doc(this.code)
                .update({
                  Used: true,
                  user: userData.id,
                  twitchname: this.twitch.toLowerCase(),
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(() => {
                  sx.collection('User')
                    .doc(userData.id)
                    .set({
                      License: {
                        DaysLeft: snap.data().Duration,
                        TotalDays: snap.data().Duration,
                        licensekey: this.code,
                        licensebeginning: firebase.firestore.FieldValue.serverTimestamp(),
                        LastStart: new Date(),
                      },
                      Package: snap.data().Package,
                      viewer: snap.data().Viewer,
                      twitchname: this.twitch.toLowerCase(),
                      follower: {
                        remaining: snap.data().Follower, sent: 0, realRemaining: snap.data().Follower, realSent: 0, sendMessage: true, dripFeedAmount: 10, dripFeedTime: 25, currentMessage: '',
                      },
                      Botpanel: {
                        Views: 5,
                        Active: false,
                        Viewlist: true,
                        CategoryChatbot: true,
                        Chatbot: true,
                        Viewbot: true,
                        chatinterval: [30, 300],
                        Loop: true,
                      },
                    }, { merge: true })
                    .then(() => {
                      sx.collection('User')
                        .doc(userData.id)
                        .get()
                        .then(snap2 => {
                        // console.log(snap.data().Package)
                        // const expiresIn = 7200
                        // const createdAt = admin.firestore.Timestamp.now().toDate()
                        // createdAt.setSeconds(createdAt.getSeconds() + expiresIn);
                        // admin.firestore.Timestamp.fromDate(createdAt)
                          if (snap2.data().Package === 'None') {
                            this.show = true
                          } else {
                            this.valueslider = snap2.data().Botpanel.Views
                            this.max = snap2.data().viewer
                            this.active = snap2.data().Botpanel.Active
                            this.showstart = !snap2.data().Botpanel.Active
                            this.showstop = snap2.data().Botpanel.Active
                            this.chatbot = snap2.data().Botpanel.Chatbot
                            this.viewbot = snap2.data().Botpanel.Viewbot
                            this.timeslider = snap2.data().Botpanel.chatinterval
                            if (snap2.data().Botpanel.chatinterval === snap2.data().Botpanel.chatintervalxx) {
                              this.timeslider = [30, 180]
                            }
                          // console.log(snap.data().Botpanel.chatinterval)
                          }
                        })
                        .then(() => {
                          this.show = false
                          this.showstart = true
                          this.$swal({
                            title: 'Succeed!',
                            text: ` You just redeemed your ${snap.data().Package}Package!`,
                            icon: 'success',
                            customClass: {
                              confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                          })
                          this.$router.go()
                        })
                    })
                    .catch(error => {
                      console.log(error)
                      this.$swal({
                        title: 'Errors!',
                        text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                        icon: 'error',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                })
            } else {
              this.$swal({
                title: 'Error!',
                text: ' This License was already redeemed, if you believe that this is an error, please contact our Support via Discord or Mail!',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          .catch(error => {
            console.error('Error adding document: ', error)
            this.$swal({
              title: 'Error!',
              text: ' This License does not exist, if you believe that this is an error, please contact our Support via Discord or Mail!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      } else {
        this.$swal({
          title: 'Error!',
          text: ' Please enter a twitchname!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

      // this.$swal({
      //   // icon: 'success',
      //   icon: 'AlertCircleIcon',
      //   title: 'This License does not exist, if you believe that this is a Failure, please contact our Support via Discord or Mail!',
      //   showConfirmButton: false,
      //   timer: 1500,
      //   customClass: {
      //     confirmButton: 'btn btn-primary',
      //   },
      //   buttonsStyling: false,
      // })
    },
    // top end
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
